<template>
 <div>
  <alerta></alerta>
 </div>
</template>

<script>
import Alerta from '../components/comum/Alerta.vue'

  export default {
    name: 'Home',
    components: {
        Alerta
    },
  }
</script>
